import {useGetPostQuery, useListPostsQuery} from '~/gql/generated';

import {QueryClient} from '@tanstack/react-query';
import {Storefront} from '@shopify/hydrogen';
import {getWaywardAndShopifyProductsByIds} from '../utils/product.utils.server';
import {mergeProducts} from '../utils/productUtils';

export async function getPaginatedPosts(
  creatorName: string,
  options: {
    client: QueryClient;
    filterVisible?: boolean;
    page: number;
  },
) {
  const client = options?.client || new QueryClient();
  let posts = null;
  try {
    const args = {creatorName, page: options.page || 1};
    const resp = await client.fetchQuery(
      useListPostsQuery.getKey(args),
      useListPostsQuery.fetcher(args),
    );
    posts = resp.listPosts || [];

    if (options.filterVisible) {
      posts = posts.filter((p) => p.displayInHomePage);
    }

    return {
      posts,
    };
  } catch (e) {
    console.error(e);
    return {
      posts: [],
    };
  }
}

async function loadPost(postId: string, client: QueryClient) {
  try {
    const result = await client.fetchQuery(
      useGetPostQuery.getKey({postId}),
      useGetPostQuery.fetcher({postId}),
    );
    return result.getPost;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getPost(
  postId: string,
  creatorName: string,
  client: QueryClient,
  storefront: Storefront,
  internal = false,
  page?: number,
  limit?: number,
) {
  try {
    const post = await loadPost(postId, client);

    if (post) {
      const {products, shopifyProducts} =
        await getWaywardAndShopifyProductsByIds(
          post?.productIds || [],
          creatorName,
          client,
          storefront,
          {
            internal,
            page,
            limit,
          },
        );

      return {
        post,
        products,
        shopifyProducts,
      };
    }

    return {
      post,
      products: [],
      shopifyProducts: [],
    };
  } catch (e) {
    console.error(e);
    return {
      post: null,
      products: [],
      shopifyProducts: [],
    };
  }
}

export async function loadPostProductImages(
  postId: string,
  creatorName: string,
  client: QueryClient,
  storefront: Storefront,
) {
  const maxProducts = 4;
  try {
    const {post, products, shopifyProducts} = await getPost(
      postId,
      creatorName,
      client,
      storefront,
      false,
      1,
      maxProducts,
    );

    return {
      media: mergeProducts(products, shopifyProducts?.nodes || []).filter(
        ({media}) => media?.[0],
      ),
      max: post?.productIds?.length || 0,
    };
  } catch (e) {
    console.error(e);
    return {
      media: [],
      max: 0,
    };
  }
}
